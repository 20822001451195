import React from 'react';
import { Query } from 'react-apollo';

import * as Core from 'components/core';
import {
  CurrentUserQuery,
  CURRENT_USER_QUERY,
} from 'components/queries/CurrentUserQuery';

const LoadInitialData: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <Query<CurrentUserQuery> query={CURRENT_USER_QUERY}>
      {({ loading }) => {
        if (loading) {
          return <Core.Loader fullPage />;
        }

        return <>{props.children}</>;
      }}
    </Query>
  );
};

export default LoadInitialData;
