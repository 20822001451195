import './Preview.scss';

import React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { compact, uniq } from 'lodash-es';

import PreviewTable from './PreviewTable';
import FontFaceDeclarations from 'components/shared/FontFaceDeclarations';
import { FontFamiliesQuery } from 'client/graphql/types/operations';
import {
  PreviewOverrideDeclarationsQuery,
  PreviewOverrideDeclarationsQueryVariables,
} from 'client/graphql/types/operations';

const DELAY = 1000;

type FontFamily = FontFamiliesQuery['families'][number];
type FontFace = FontFamiliesQuery['families'][number]['faces'][number];

interface Props {
  family: FontFamily;
}

interface State {
  activeIndex: number;
  hovered: boolean;
}

export class Preview extends React.PureComponent<Props, State> {
  static displayName = 'Families.Preview';

  state = {
    activeIndex: 0,
    hovered: false,
  };

  intervalId?: number;

  componentWillUnmount() {
    this.clearInterval();
  }

  clearInterval() {
    if (this.intervalId != null) {
      clearInterval(this.intervalId);
    }
  }

  next = () => {
    const { family } = this.props;

    this.setState((state) => ({
      activeIndex: (state.activeIndex + 1) % family.previews.length,
    }));
  };

  handleMouseOver = () => {
    this.clearInterval();
    this.next();
    this.intervalId = window.setInterval(this.next, DELAY);
    this.setState({ hovered: true });
  };

  handleMouseOut = () => {
    this.clearInterval();
    this.setState({ hovered: false });
  };

  get previewFaces() {
    const { family } = this.props;

    const previewsWithoutOverrideFonts = family.previews.filter(
      (preview) => !preview.hasOverrideFont,
    );

    return family.faces.filter((face) =>
      previewsWithoutOverrideFonts.some(
        (preview) => preview.face.id === face.id,
      ),
    );
  }

  render() {
    const { family } = this.props;
    const { activeIndex } = this.state;

    const preview = family.previews[activeIndex];

    if (preview == null) {
      return null;
    }

    const activeFace = family.faces.find((face) => face.id === preview.face.id);

    if (activeFace == null) {
      throw new Error('No active face');
    }

    const cssClassName = preview.hasOverrideFont
      ? preview.overrideCssClassName
      : activeFace.cssClassName;

    return (
      <FontFaceDeclarations ids={this.previewFaces.map((face) => face.id)}>
        {({ loading }) => (
          <PreviewOverrideDeclarations family={family}>
            {({ loading: overridesLoading }) => (
              <Link
                to={`/families/${family.slug}`}
                // Mouse over and out are used rather than enters and leaves to
                // work around a bug when children re-rendering breaks some
                // events. Child elements have pointer events disabled so this
                // works.
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
                className={classNames('FamiliesPreview', {
                  'is-loading': loading || overridesLoading,
                })}
                style={{
                  ...(this.state.hovered
                    ? {
                        backgroundColor: `#${family.backgroundColor}66`,
                      }
                    : {}),
                }}
              >
                <div
                  // The key is specified so a new element is mounted when the
                  // index changes to work around a bug where changing styles
                  // would cause the browser scroll position to change.
                  key={activeIndex}
                  className={classNames(
                    'FamiliesPreview-familyName',
                    cssClassName,
                  )}
                  style={{
                    textTransform: preview.textTransform.toLowerCase() as
                      | 'capitalize'
                      | 'uppercase',
                    letterSpacing: `${preview.letterSpacing}em`,
                    lineHeight: `${preview.lineHeight}em`,
                    ...(this.state.hovered
                      ? {
                          backgroundColor: `#${family.backgroundColor}`,
                          color: `#${family.foregroundColor}`,
                        }
                      : {}),
                  }}
                >
                  <span style={{ fontSize: `${preview.fontSizeScale}em` }}>
                    {family.name}
                  </span>
                </div>

                <div className="FamiliesPreview-details">
                  <div className="FamiliesPreview-description">
                    <FontFaceDescription family={family} face={activeFace} />
                  </div>

                  <div className="FamiliesPreview-table">
                    <PreviewTable
                      faces={family.faces}
                      activeFace={activeFace}
                    />
                  </div>
                </div>
              </Link>
            )}
          </PreviewOverrideDeclarations>
        )}
      </FontFaceDeclarations>
    );
  }
}

const useDescription = (fontFamily: FontFamily) => {
  const { t } = useTranslation();

  const hasMultipleSizes = React.useMemo(
    () => uniq(fontFamily.faces.map(({ size }) => size)).length > 1,
    [fontFamily],
  );

  return React.useCallback(
    (fontFace: FontFace) => {
      const hasMultipleWidths =
        uniq(
          fontFamily.faces
            .filter(({ size }) => size === fontFace.size)
            .map(({ width }) => width),
        ).length > 1;

      const displaySize = hasMultipleSizes
        ? t(`font.size.${fontFace.size}`)
        : undefined;

      const displayWidth = hasMultipleWidths
        ? t(`font.width.${fontFace.width}`)
        : undefined;

      const displayWeight =
        !hasMultipleSizes ||
        fontFace.weight !== 'regular' ||
        (fontFace.size !== 'headline' && fontFace.style !== 'italic')
          ? t(`font.weight.${fontFace.weight}`)
          : undefined;

      const displayStyle =
        fontFace.style !== 'roman'
          ? t(`font.style.${fontFace.style}`)
          : undefined;

      return compact([
        displaySize,
        displayWidth,
        displayWeight,
        displayStyle,
      ]).join(' ');
    },
    [t, fontFamily, hasMultipleSizes],
  );
};

const FontFaceDescription: React.FC<{
  family: FontFamily;
  face: FontFace;
}> = (props) => {
  const { family, face } = props;

  return <>{useDescription(family)(face)}</>;
};

const PREVIEW_OVERRIDE_DECLARATIONS_QUERY = gql`
  query PreviewOverrideDeclarationsQuery($familySlug: String!) {
    family(slug: $familySlug) {
      previews {
        id
        overrideCssDeclaration
      }
    }
  }
`;

const PreviewOverrideDeclarations: React.FC<{
  family: FontFamily;
  children(props: { loading: boolean }): React.ReactNode;
}> = (props) => {
  const { family, children } = props;

  if (family.previews.every((preview) => !preview.hasOverrideFont)) {
    return <>{children({ loading: false })}</>;
  }

  return (
    <Query<
      PreviewOverrideDeclarationsQuery,
      PreviewOverrideDeclarationsQueryVariables
    >
      query={PREVIEW_OVERRIDE_DECLARATIONS_QUERY}
      variables={{ familySlug: family.slug }}
    >
      {({ data, loading }) => (
        <>
          {children({ loading })}
          {data?.family?.previews?.map((preview) => {
            if (preview.overrideCssDeclaration == null) {
              return null;
            }

            return (
              <style
                key={preview.id}
                dangerouslySetInnerHTML={{
                  __html: preview.overrideCssDeclaration,
                }}
              />
            );
          })}
        </>
      )}
    </Query>
  );
};

export default Preview;
